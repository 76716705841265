<template>
  <b-row>
    <b-col lg="3">
      <b-card no-body class="chat-widget">
        <b-card-header>
          <div class="d-flex align-items-center">
            <feather-icon size="24" class="mr-1" icon="ListIcon"/>
            <b-card-title class="ml-25">操作记录</b-card-title>
          </div>
        </b-card-header>
       
       <b-overlay variants="light" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
       <section class="chat-app-window">
        <vue-perfect-scrollbar  ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
          <app-timeline>
            <!-- <app-timeline-item v-for="data in items" :key="data.id" :title="data.adm_id" :subtitle="data.data" :time="data.datetime" :variant="data.color">
            </app-timeline-item> -->
            <app-timeline-item :variant="data.color" v-for="data in timeline" :key="data.id" :icon="data.icons">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                    <h6><feather-icon icon="UsersIcon"/>&nbsp;操作员:{{data.adm_id}}&nbsp;&nbsp;<feather-icon icon="ToolIcon"/>&nbsp;类型:{{data.data.cz_title}}</h6>
                    <small class="text-muted">{{data.datetime}}</small>
                </div>
                <b-media class="mt-1">
                    <template #aside>
                        <img :src="data.data.img" class="product"/>
                    </template>
                    <h6 style="display:inline-block;width:10rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{data.data.title}}</h6>
                    <p class="mb-0">
                        {{data.data.sub_title}}
                    </p>
                </b-media>
             </app-timeline-item>
          
          </app-timeline>
        </vue-perfect-scrollbar>
       </section>
       </b-overlay>
 
      </b-card>
    </b-col>
    <b-col lg="9">
      
      <b-card ref="refreshCard"  no-body class="chat-widget">
        <b-card-header>
          <div class="d-flex align-items-center">
            <feather-icon size="24" class="mr-1" icon="CalendarIcon"/>
            <b-card-title class="ml-25">API日志</b-card-title>
          </div>
        </b-card-header>
        <b-card-body class="user-chats">
        <b-row>
          <b-col lg="7" class="mb-1">
            <b-button-group>
              <!-- <b-button variant="primary" class="btn-icon" v-b-toggle.sidebar-1><feather-icon icon="PlusCircleIcon" /></b-button> -->
              <b-button variant="primary" class="btn-icon" @click="selectAllRows()"><feather-icon
                  icon="CheckCircleIcon" />&nbsp;<span class="align-middle">{{ select_mode }}</span></b-button>
              <b-button variant="primary" class="btn-icon" @click="deleteAllRows()"><feather-icon
                  icon="Trash2Icon" />&nbsp;<span class="align-middle">批量删除</span></b-button>
            </b-button-group>
          </b-col>
          <b-col lg="5" class="mb-1">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text><feather-icon icon="SearchIcon" /></b-input-group-prepend>
              <b-form-input placeholder="路由地址" v-model="search_name" />
              <b-input-group-append><b-button variant="outline-primary"
                  @click="get_data(2)">检索</b-button></b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-overlay variants="light" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
              <b-table ref="selectableTable" @row-selected="onRowSelected" selectable select-mode="multi" class="tabledata"
                striped small hover responsive="sm" :items="items" :fields="fields" sticky-header no-border-collapse>
                <template #cell(data)="data">
                  <b-button :id="'p' + data.index" variant="gradient-primary" class="btn-icon" size="sm"><feather-icon
                      icon="ZoomInIcon" /></b-button>
                  <b-popover :target="'p' + data.index" triggers="focus" placement="top">
                    <template #title>
                      <span>参数</span>
                    </template>
                    <span>{{ data.value }}</span>
                  </b-popover>
                </template>
              </b-table>
            </b-overlay>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text> <feather-icon icon="ChevronLeftIcon" size="18" /></template>
              <template #next-text> <feather-icon icon="ChevronRightIcon" size="18" /></template>
            </b-pagination>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-sidebar id="sidebar-1" shadow bg-variant="white" right backdrop width="30%"></b-sidebar>
          </b-col>
        </b-row>
        </b-card-body>
      </b-card>
      

    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BPagination, BButton, BPopover, BTable, BFormGroup, BFormSelect, BFormInput,
  BInputGroupAppend, BButtonGroup, BRow, BCol, BInputGroup, BInputGroupPrepend,
  BSidebar, VBToggle, BBadge, BDropdown, BDropdownItem, BMedia, BAvatar,

} from 'bootstrap-vue'
import { BCardBody, BCardHeader, BCardTitle,BOverlay} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BMedia,
    BAvatar,
    BOverlay,
    BBadge,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BTable,
    BCard,
    BCardText,
    BCardActions,
    BButton,
    BPopover,
    BPagination,
    BFormGroup,
    BFormSelect,
    BInputGroupAppend,
    BButtonGroup,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BSidebar,
    VBToggle,
    vSelect,
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID', },
        { key: 'adm_id', label: '用户', },
        { key: 'path', label: '路由', },
        { key: 'act', label: '函数', },
        { key: 'datetime', label: '时间', },
        { key: 'data', label: '数据', }, 
      ],
      perfectScrollbarSettings: {
        maxScrollbarLength: 250,
        wheelPropagation: false,
      },
      items: [],
      timeline:[],
      currentPage: 1,
      perPage: 20,
      rows: 0,
      search_name: "",
      selected: [],
      select_all: 0,
      select_mode: "全选",
      showLoading: false,
    }
  },
  created() {
    this.get_data(2)
    this.get_data(3)
  },
  watch: {
    currentPage: function (oldV, newV) {
      this.get_data(2) 
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
      if(items.length>0){
        this.showToast('success', '选中了' + (items.length) + '条数据!')
      }
    },
    selectAllRows() {
      if (this.select_all == 1) {
        this.clearSelected()
        return
      }
      this.$refs.selectableTable.selectAllRows()
      this.select_all = 1
      this.select_mode = "取消全选"
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.select_all = 0
      this.select_mode = "全选"
    },
    get_data(type) {
      this.showLoading = true
      let dataObj = {
        act: "lst",
        pge: this.currentPage,
        cnt: this.perPage,
        name: this.search_name,
        type: type,
      }
      this.$http.post('svr/admin_logs.php', dataObj)
        .then(res => {
          if(type==2){
            this.items = res.data.lst
            this.rows = res.data.cnt_all
            setTimeout(() => {
              this.showLoading = false
            }, 300)
          }else{
              this.timeline = res.data.lst
          }
          
        })
    },
    del_data(ids) {
      let dataObj = {
        act: "del",
        id: ids,
      }
      this.$http.post('svr/admin_logs.php', dataObj)
        .then(res => {
          this.showToast('success', '删除成功')
          this.get_data(2)
        })
    },
    deleteAllRows() {
      let ids = this.set_delids()
      if (!this.selected.length > 0) {
        this.showToast('danger', '请先选择您要删除的数据!')
        return
      }
      this.$swal({
        title: '确定删除数据吗?',
        text: "此操作将不可逆,请谨慎操作",
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '我确定!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.del_data(ids)
        }
      })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '系统提示',
          icon: 'BellIcon',
          text: text,
          variant,
        },
      })
    },
    set_delids() {
      let val = this.selected
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      ids = ids.join();
      return ids;
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import "@core/scss/base/pages/app-chat-list.scss";
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';

.dark-layout {
  .bg-light {
      background-color: $theme-dark-body-bg !important;
      border-radius: 0 !important;
  }
}

.tabledata {
  border-radius: 3px;
}

.b-table-sticky-header {
  max-height: calc(100vh - 390px) !important;
}

.custom-select {
  padding: 0.438rem 0rem 0.438rem 0.6rem;
  width: 3.4rem;
}

.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
.user-chats{
  height: calc(100vh - 260px) !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.card{
  margin-bottom: 0 !important;
}
.product{
  height: 45px;
  border-radius: 5px;
}
 
</style>
